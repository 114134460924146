import { HHSimpleTable, IColumnConfig } from "src/components/parts/HHSimpleTable";
import { useCallback, useMemo, useState } from "react";
import { TEditorComponentProps } from "./TEditorComponentProps";
import { 
  appendRangeToSortedPriceBracketsForSubscription, 
  getSortedPriceBracketsForSubscription, 
  getSortedVariablePriceBrackets, 
  getSortedVariablePriceOverrideBrackets, 
  IRangedVariableBasePrice, 
} from "@hydra/internal";
import { 
  assertValidBillingType, 
  BILLING_FEATURE_VARIABLE_BASE_PRICE, 
  billingTypeHasFeature, 
  getBillingFeatureMetadata, 
  TValidBillingFeature, 
} from "@llws/hydra-shared";
import { ISubscriptionVariableBasePriceEditable } from "./EditableTypes";
import { Box, Button } from "@mui/material";
import { VariableBasePriceOverrideModal } from "./VariableBasePriceOverrideModal";

export const VariableBasePriceEditor = (
  { subscription, setSubscriptionProp }: TEditorComponentProps<ISubscriptionVariableBasePriceEditable>,
) => {

  const [ openVariableBasePriceOverrideModal, setOpenVariableBasePriceOverrideModal ] = useState(false);

  const billingTypeId = useMemo(() => (subscription?.baseSubscription?.service?.billingTypeId), [ subscription ]);

  const billingFeatureMetadata = useCallback((billingTypeId: number, feature: TValidBillingFeature, field: string) => (
    (getBillingFeatureMetadata(assertValidBillingType(billingTypeId), feature as any) as any)[field] //TODO: get rid of the any
  ), []);

  const variableBaseBrackets = useMemo(() => {
    const subData = {
      baseSubscription: { variableBasePrices: subscription.baseSubscription.variableBasePrices.slice() },
    };
    return appendRangeToSortedPriceBracketsForSubscription(getSortedVariablePriceBrackets(subData));
  }, [subscription]);

  const variableBaseOverrideBrackets = useMemo(() => {
    const subData = {
      variableBasePriceOverrides: subscription.variableBasePriceOverrides.slice(),
    };
    return appendRangeToSortedPriceBracketsForSubscription(getSortedVariablePriceOverrideBrackets(subData));
  }, [subscription]);

  const variablePriceBracketsColumnsConfig: IColumnConfig<IRangedVariableBasePrice>[]= useMemo(() => (
    [
      {
        headerLabel: 'Range',
        fieldName: 'range',
      },
      {
        headerLabel: 'Base Price',
        fieldName: 'basePrice',
        format: (value) => `$${value}`,
      },
      {
        headerLabel: 'Cost Per Unit',
        fieldName: 'costPerUnit',
        format: (value) => `$${value}`,
      },
    ]
  ), []);

  const variableOverridePriceBracketsColumnsConfig: IColumnConfig<IRangedVariableBasePrice>[]= useMemo(() => (
    [
      {
        headerLabel: 'Range',
        fieldName: 'range',
      },
      {
        headerLabel: 'Base Price',
        fieldName: 'basePrice',
        format: (value) => `$${value}`,
      },
      {
        headerLabel: 'Cost Per Unit',
        fieldName: 'costPerUnit',
        format: (value) => `$${value}`,
      },
    ]
  ), []);

  return <>
    {
      billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_VARIABLE_BASE_PRICE)
                    && <>
                      <label htmlFor="variable base price">
                        <h4>
                          { `${billingFeatureMetadata(billingTypeId, BILLING_FEATURE_VARIABLE_BASE_PRICE, 'label')} ` }
                          Variable Base Price{ subscription.variableBasePriceOverrides?.length ? ' Overrides': 's'}
                        </h4>
                      </label>
                      <VariableBasePriceOverrideModal 
                        open={openVariableBasePriceOverrideModal}
                        setOpen={setOpenVariableBasePriceOverrideModal}
                        onConfirm={(brackets) => {
                          const variableBasePriceOverrides = brackets.map((bracket) => (
                            {
                              id: bracket.id ?? 0,
                              subscriptionId: subscription.id ?? 0,
                              maxValue: bracket.maxValue?.length ? bracket.maxValue : null,
                              basePrice: bracket.basePrice,
                              costPerUnit: bracket.costPerUnit?.length ? bracket.costPerUnit : null,
                              minDuration: null,
                            }
                          ));
                          setSubscriptionProp('variableBasePriceOverrides', variableBasePriceOverrides);
                        }}
                        basePriceBrackets={variableBaseOverrideBrackets}
                      />
                      {
                        subscription.variableBasePriceOverrides?.length
                          ? <>
                            <HHSimpleTable<IRangedVariableBasePrice>
                              rows={variableBaseOverrideBrackets}
                              columns={variableOverridePriceBracketsColumnsConfig}
                              rowHover={false}
                            />
                            <Box pt={2}>
                              <Button
                                color='primary'
                                variant='contained'
                                onClick={() => setOpenVariableBasePriceOverrideModal(true)}
                              >
                                Update variable base price overrides
                              </Button>
                            </Box>
                          </>
                          : <>
                            <HHSimpleTable<IRangedVariableBasePrice>
                              rows={variableBaseBrackets}
                              columns={variablePriceBracketsColumnsConfig}
                              rowHover={false}
                            />
                            <Box pt={2}>
                              <Button
                                color='primary'
                                variant='contained'
                                onClick={() => setOpenVariableBasePriceOverrideModal(true)}
                              >
                                Create variable base price overrides
                              </Button>
                            </Box>
                          </>
                      }  
                    </>
    }
  </>;
};