import {
  Accordion, AccordionDetails, AccordionSummary, IconButton, InputAdornment, TextField,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HHSimpleTable, IColumnConfig } from "src/components/parts/HHSimpleTable";
import { useCallback, useMemo } from "react";
import { floatVal, Hash } from "@jamesgmarks/utilities";
import { TEditorComponentProps } from "./TEditorComponentProps";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "@mui/icons-material/Add";
import { makeYmd } from "src/app-utils";
import { ICommissionSchemesEditable, SubscriptionEditable } from "./EditableTypes";
import { SalesRepDropdown } from "./SalesRepDropdown";
export const CommissionEditor = (
  { subscription, setSubscriptionProp }: TEditorComponentProps<SubscriptionEditable>,
) => {

  type TCommissionRate = {
    startDate?: string | null;
    rate?: string;
  }
  type TCommissionSchemeRowData = TCommissionRate & {
    salesRepId: number;
    index:number
  }

  const createNewSchemeForSalesRep = (salesRepId: number) => {
    const newScheme : ICommissionSchemesEditable = {
      id: 0,
      salesRepId,
      scheme: {
        rates: [
        ],
        version: '1', // TODO: get version from somewhere?
      },
      subscriptionId: 0,
      label: "",
      created: undefined,
      createdUserId: 0,
      modified: null,
      modifiedUserId: null,
    };
    return newScheme;
  };

  const getCommissionSchemeRowDataForSalesRep  = useCallback((salesRepId: number) => {

    const commissionScheme = subscription.commissionSchemes?.find((cs) => cs.salesRepId === salesRepId);
    if (!commissionScheme || !commissionScheme.scheme?.rates) {
      return [];
    }
    const schemeRows: TCommissionSchemeRowData[] =  commissionScheme.scheme.rates.map((rate, index) => ({
      ...rate,
      salesRepId,
      index,
    }));

    return schemeRows;
  }, [subscription?.commissionSchemes]);

  const applySchemeChanges= useCallback((
    subscription:SubscriptionEditable,
    salesRepId:number,
    schemeChangeFunction: (commissionSchemeForRep:ICommissionSchemesEditable) =>  ({
      startDate?: string | null;
      rate?: string ;
    }[]),
  )=>{
    const commissionSchemeForRep = (
      subscription.commissionSchemes?.find((cs) => cs.salesRepId === salesRepId)
    ) ?? createNewSchemeForSalesRep(salesRepId);

    const newRates = schemeChangeFunction(commissionSchemeForRep);

    const updatedCommissionScheme = {
      ...commissionSchemeForRep,
      scheme: {
        ...commissionSchemeForRep.scheme,
        rates: newRates,
      },
    };

    const newSchemes = [
      ...(subscription.commissionSchemes?.filter((cs) => cs.salesRepId !== salesRepId) ?? []),
      updatedCommissionScheme,
    ];

    return newSchemes ?? [];
  }, []);

  const appendNewRateToScheme = useCallback((subscription:SubscriptionEditable, salesRepId: number) =>{
    const newSchemes = applySchemeChanges(subscription, salesRepId, (commissionSchemeForRep) => {
      if(!commissionSchemeForRep?.scheme?.rates) {
        throw new Error('No commission scheme found for sales rep: ' + salesRepId);
      }
      return [
        ...commissionSchemeForRep?.scheme.rates,
        {
          startDate: makeYmd(new Date()),
          rate: '0',
        },
      ];
    });

    return newSchemes;
  }, [applySchemeChanges]);

  const removeRateFromScheme = useCallback((subscription:SubscriptionEditable, salesRepId: number, index:number) =>{

    const newSchemes = applySchemeChanges(subscription, salesRepId, (commissionSchemeForRep) => {
      if(!commissionSchemeForRep?.scheme?.rates) {
        throw new Error('No commission scheme found for sales rep: ' + salesRepId);
      }
      const rates = commissionSchemeForRep.scheme.rates;
      return  rates.slice(0, index).concat(rates.slice(index + 1));
    });
    return newSchemes;
  }, [applySchemeChanges]);

  const changePropertyValueOnScheme = useCallback((
    subscription:SubscriptionEditable,
    salesRepId: number,
    index:number,
    propName:keyof TCommissionSchemeRowData,
    value:string,
  ) => {
    if(!subscription) {
      throw new Error('No subscription found');
    }
    const newSchemes = applySchemeChanges(subscription, salesRepId, (commissionSchemeForRep) => {
      if(!commissionSchemeForRep?.scheme?.rates) {
        throw new Error('No commission scheme found for sales rep: ' + salesRepId);
      }
      const newRates = commissionSchemeForRep?.scheme.rates.map((rate, i) => {
        if (i === index) {
          return {
            ...rate,
            [propName]: value,
          };
        }
        return rate;
      });

      return newRates;
    });
    return newSchemes;
  }, [applySchemeChanges]);

  const commissionRateTableColumnsConfig: IColumnConfig<TCommissionSchemeRowData>[] = useMemo(() => (
    [
      {
        headerLabel: 'Start Date',
        fieldName: 'startDate',
        render: (data: TCommissionSchemeRowData) => (
          <TextField
            type="date"
            sx={{
              bgcolor: 'text.disabled',  input: { color: 'white' },
              '& input[type="date"]::-webkit-calendar-picker-indicator': {filter: 'invert(1)'},
            }}
            size="small"
            value={data.startDate ? new Date(data.startDate).toISOString().split('T')[0] : ''}

            onChange={
              (e) => {
                const newSchemes =(
                  changePropertyValueOnScheme(subscription, data.salesRepId, data.index, 'startDate', e.target.value)
                );

                if(!newSchemes) {
                  throw new Error('Unable to edit startDate on commission scheme for sales rep: ' + data.salesRepId);
                }
                setSubscriptionProp(
                  'commissionSchemes',
                  newSchemes,
                );

              }
            }
          />
        ),
      },
      {
        headerLabel: 'Commission Rate',
        fieldName: 'rate',
        render: (data: TCommissionSchemeRowData) => (
          <TextField
            type="number"
            sx={{ bgcolor: 'text.disabled',  input: { color: 'white' }}}
            size="small"
            value={(data.rate && Number(floatVal(data.rate) * 100).toLocaleString() )?? ''}
            onChange={
              (e) => {
                if (subscription) {
                  const newSchemes =(
                    changePropertyValueOnScheme(
                      subscription, data.salesRepId, data.index, 'rate', `${(floatVal(e.target.value)/100)}`,
                    )
                  );

                  if(!newSchemes) {
                    throw new Error('Unable to edit startDate on commission scheme for sales rep: ' + data.salesRepId);
                  }
                  setSubscriptionProp(
                    'commissionSchemes',
                    newSchemes,
                  );
                }
              }
            }

            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ '& .MuiTypography-root': { color: 'white' } }}
                > % </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        headerLabel: '',
        fieldName: 'index',
        headerRender: (metaData:Hash) => (
          <IconButton
            onClick={() => {
              const newSchemes = appendNewRateToScheme(subscription, metaData.salesRepId);

              if(!newSchemes) {
                throw new Error('Unable to edit startDate on commission scheme for sales rep: ' + metaData.salesRepId);
              }
              setSubscriptionProp(
                'commissionSchemes',
                newSchemes,
              );
            }}
          >
            <AddIcon
              sx={{
                backgroundColor: 'white',
                borderRadius: '5px',
                color: 'black',
                cursor: 'pointer',
                fontSize: '2rem',
                padding: 1,
                '&:hover': { opacity: 0.8 },
              }}
            />
          </IconButton>
        ),
        render: (data: TCommissionSchemeRowData) => (
          <IconButton
            onClick={() =>{
              const newSchemes = removeRateFromScheme(subscription, data.salesRepId, data.index);
              setSubscriptionProp(
                'commissionSchemes',
                newSchemes,
              );

            }}
          >
            <DeleteIcon
              sx={{
                backgroundColor: 'crimson',
                borderRadius: '5px',
                color: 'white',
                cursor: 'pointer',
                fontSize: '2rem',
                padding: 1,
                '&:hover': { opacity: 0.8 },
              }}
            />
          </IconButton>
        ),
      },
    ]
  ), [changePropertyValueOnScheme, subscription, setSubscriptionProp, appendNewRateToScheme, removeRateFromScheme]);

  return <><label>
    <h4> Commissions </h4>
  </label>
  <div>
    <Accordion expanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="primary-salesrep-commission-content"
        id="primary-salesrep-commission-header"
      >
        Primary Sales Rep:
        <SalesRepDropdown salesRepId={subscription.salesRepId ?? 0} setSalesRepId={(salesRepId) => {
          setSubscriptionProp(
            'salesRepId',
            salesRepId,
          );
        }}></SalesRepDropdown>
      </AccordionSummary>
      <AccordionDetails>
        <HHSimpleTable<TCommissionSchemeRowData>
          rows={subscription.salesRepId ? getCommissionSchemeRowDataForSalesRep(subscription.salesRepId) :[]}
          columns={commissionRateTableColumnsConfig}
          rowHover={false}
          headerMetaData={{salesRepId: subscription.salesRepId}}
        />
      </AccordionDetails>
    </Accordion>
    {
      <Accordion expanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="parent-salesrep-commission-content"
          id="parent-salesrep-commission-header"
        >
          Parent Sales Rep:
          <SalesRepDropdown salesRepId={subscription.parentSalesRepId ?? 0} setSalesRepId={(salesRepId) => {
            setSubscriptionProp(
              'parentSalesRepId',
              salesRepId,
            );
          }}></SalesRepDropdown>
        </AccordionSummary>
        <AccordionDetails>
          <HHSimpleTable<TCommissionSchemeRowData>
            rows={getCommissionSchemeRowDataForSalesRep(subscription.parentSalesRepId ?? 0)}
            columns={commissionRateTableColumnsConfig}
            rowHover={false}
            headerMetaData={{salesRepId: subscription.parentSalesRepId}}
          />
        </AccordionDetails>
      </Accordion>
    }
  </div>
  </>;
};