import {
  FormControl, InputLabel, MenuItem, Select,
} from "@mui/material";

import { useEffect } from "react";
import { def, intVal } from "@jamesgmarks/utilities";
import { useAppSelector } from "src/redux/hooks";
import { loadSalesReps } from "src/redux/features/commissions/actions";
export const SalesRepDropdown = (
  {salesRepId, setSalesRepId}:{salesRepId:number, setSalesRepId:(salesRepId:number) => void},
) => {
  const salesReps = useAppSelector(state => state.commissions.salesRepList);

  useEffect(() => {

    if (!def(salesReps) || (salesReps.length === 0)) {
      loadSalesReps();
    }

  }, [salesReps]);
  return<>
    <FormControl sx={{ m: 1, minWidth: 200 }}  size="small">
      <InputLabel id="sales-rep-select-label">Sales Rep</InputLabel>
      <Select
        labelId="sales-rep-select-label"
        id="sales-rep-select"
        value={salesRepId}
        label="Sales Rep"
        onChange={(e) => {setSalesRepId(intVal(e.target.value));}}
      >
        {salesReps.map((s)=>{
          return <MenuItem value={s.id}>{`${s.user.firstName + ' ' +  s.user.lastName}`}</MenuItem>;
        })}
      </Select>
    </FormControl>
  </>;
};