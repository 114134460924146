import {
  assertValidBillingType, BILLING_FEATURE_PROPERTY_LIST, billingTypeHasFeature, getBillingFeatureMetadata, TValidBillingFeature,
} from "@llws/hydra-shared";
import { TEditorComponentProps } from "./TEditorComponentProps";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IColumnConfig, HHSimpleTable, IFooterRowConfig } from "src/components/parts/HHSimpleTable";
import {
  Box, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, Tooltip,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { PropertyListModal } from "./PropertyListModal";
import AddIcon from "@mui/icons-material/Add";
import { floatVal, Nullable } from "@jamesgmarks/utilities";
import { ISubscriptionHasBuildingsEditable, SubscriptionEditable } from "./EditableTypes";
import { useAppSelector } from "src/redux/hooks";
import { getOne } from "src/redux/features/dynamic/actions";

export const SubscriptionsHasBuildingsEditor = (
  { subscription, setSubscriptionProp }: TEditorComponentProps<SubscriptionEditable>,
) => {

  const [ openPropertyListModal, setOpenPropertyListModal ] = useState(false);

  const [ buildingId, setBuildingId ] = useState<Nullable<number>>(null);
  const [ priceAdded, setPriceAdded ] = useState<Nullable<string>>(null);

  const [isBuildingLoaded, setIsBuildingLoaded] = useState(false);

  const building = useAppSelector(
    state => state.dynamic.data.buildings?.single,
  );
  
  useEffect(() => {
    const fetchBuilding = async () => {
      if (buildingId) {
        setIsBuildingLoaded(false);
        await getOne('buildings', buildingId, []); 
        setIsBuildingLoaded(true); 
      }
    };
  
    fetchBuilding();
  }, [buildingId]);
  
  useEffect(() => {
    if (isBuildingLoaded && buildingId && priceAdded) {
      createNewSubscriptionHasBuildingRecord(buildingId, priceAdded);
      setBuildingId(null);
      setPriceAdded(null);
    }
  }, [isBuildingLoaded, buildingId, priceAdded]);
  
  const handleAddBuilding = (buildingId: number, price: string) => {
    setBuildingId(buildingId);
    setPriceAdded(price); 
  };

  const billingTypeId = useMemo(() => (subscription?.baseSubscription?.service?.billingTypeId), [ subscription ]);
  const billingFeatureMetadata = useCallback((billingTypeId: number, feature: TValidBillingFeature, field: string) => (
    (getBillingFeatureMetadata(assertValidBillingType(billingTypeId), feature as any) as any)[field]
  ), []);

  const shbFooterConfig: IFooterRowConfig<ISubscriptionHasBuildingsEditable> = useMemo(() => ({
    cells: [
      {
        label: 'Total',
      },
      {
        calculate: (rows: ISubscriptionHasBuildingsEditable[]) => (
          rows.reduce((sum, shb) => sum + floatVal(shb.price ?? 0), 0).toFixed(2)
        ),
      },
      {
        label: '',
      },
    ],
  }), []);

  const subscriptionHasBuildingsColumnsConfig: IColumnConfig<ISubscriptionHasBuildingsEditable>[]= useMemo(() => (
    [
      {
        headerLabel: 'Building',
        fieldName: 'building',
        render: (data: ISubscriptionHasBuildingsEditable) => (
          <Tooltip
            title={data.building?.streetNumber + ' ' + data.building?.streetName}
            placement="top-start"
          >
            <div>
              {data.building?.buildingName}
            </div>
          </Tooltip>

        ),
      },
      {
        headerLabel: 'Price',
        fieldName: 'price',
        format: (value) => `$${value}`,
        render: (data: ISubscriptionHasBuildingsEditable) => (
          <TextField
            type="number"
            sx={{background: "white"}}
            size="small"
            value={data.price ?? ''}
            onChange={
              (e) => {
                if (subscription) {
                  setSubscriptionProp(
                    'subscriptionsHasBuildings',
                    (subscription?.subscriptionsHasBuildings ?? []).map((shb) => ({
                      ...shb,
                      price: shb.id === data.id ? e.target.value : shb.price,
                    })),
                  );
                }
              }
            }
            inputProps={{
              step: 0.01,
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        ),
      },
      {
        headerLabel: ' ',
        fieldName: 'range',
        headerRender: () => (
          <IconButton
            onClick={() => setOpenPropertyListModal(true) }
          >
            <AddIcon
              sx={{
                backgroundColor: 'white',
                borderRadius: '5px',
                color: 'black',
                cursor: 'pointer',
                fontSize: '2rem',
                padding: 1,
                '&:hover': { opacity: 0.8 },
              }}
            />
          </IconButton>
        ),
      },
    ]
  ), [subscription, setSubscriptionProp]);

  const createNewSubscriptionHasBuildingRecord = (buildingId: number, price: string) => {
    const newSubscriptionHasBuilding: ISubscriptionHasBuildingsEditable = {
      id: 0,
      subscriptionId: subscription.id ?? 0,
      activeDate: null,
      buildingId,
      price: price,
      created: undefined,
      createdUserId: 0,
      modified: null,
      modifiedUserId: null,
      building: building ?? undefined,
      requestedDate: null,
    };
    setSubscriptionProp(
      'subscriptionsHasBuildings',
      ((subscription?.subscriptionsHasBuildings ?? []).concat(newSubscriptionHasBuilding)
      ),
    );
  };

  return <>{

    billingTypeHasFeature(assertValidBillingType(billingTypeId ?? 0), BILLING_FEATURE_PROPERTY_LIST)
  && <>
    <label htmlFor="building-list">
      <h4>Buildings: Rentsync Buildings</h4>
    </label>
    {
      billingFeatureMetadata(billingTypeId ?? 0, BILLING_FEATURE_PROPERTY_LIST, 'allowIndividualPropertyPricing')
    &&
    <>
      <HHSimpleTable<ISubscriptionHasBuildingsEditable>
        rows={subscription.subscriptionsHasBuildings ?? []}
        columns={subscriptionHasBuildingsColumnsConfig}
        rowHover={false}
        footer={shbFooterConfig}
        scrollable={true}
        maxHeight="600px"
      />
      <PropertyListModal
        open={openPropertyListModal}
        accountId={subscription.client?.freshbooksClientId ?? 0}
        setOpen={setOpenPropertyListModal}
        onConfirm={
          (buildingId, price) => {
            if (buildingId) {
              handleAddBuilding(buildingId, price);
            }
          }
        }
        buildingStatuses={['deleted']}
        excludeStatus
      />
    </>
    }
    {
      billingFeatureMetadata(billingTypeId ?? 0, BILLING_FEATURE_PROPERTY_LIST, 'allowEvenBuildingSplit')
    &&
    <Box>
      <FormControlLabel
        label="Enable even building split"
        control={
          <Checkbox
            checked={!!subscription.evenBuildingSplit}
            onChange={(e) => {
              setSubscriptionProp('evenBuildingSplit', e.target.checked ? 1 : 0);
            }
            }
          />
        }
      />
    </Box>
    }
  </>
  }</>;

};