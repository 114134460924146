import { appendRangeToSortedPriceBracketsForSubscription, IRangedVariableBasePrice } from "@hydra/internal";
import { intVal, Nullable } from "@jamesgmarks/utilities";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField, 
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useMemo, useState } from "react";
import { HHSimpleTable, IColumnConfig } from "src/components/parts/HHSimpleTable";
    
interface IPropertyModalProps {
  open: boolean;
  basePriceBrackets: IRangedVariableBasePrice[];
  setOpen: (open: boolean) => void;
  onConfirm: (brackets: IRangedVariableBasePrice[]) => void;
  onCancel?: () => void;
  onClose?: () => void;
  
}
    
export const VariableBasePriceOverrideModal = ({
  open,
  basePriceBrackets,
  setOpen,
  onConfirm,
  onCancel,
  onClose,
}: IPropertyModalProps) => {

  const [editableBrackets, setEditableBrackets] = useState<IRangedVariableBasePrice[]>(
    () => {
      const numberOfBrackets = basePriceBrackets.length;
      if (!numberOfBrackets) {
        return [
          { maxValue: '1', basePrice: '1', costPerUnit: null, range: '1' },
          { maxValue: null, basePrice: '1', costPerUnit: null, range: '2+' },
        ];
      }
      return basePriceBrackets.slice();
    },
  );

  useEffect(() => {
    if (basePriceBrackets.length) {
      setEditableBrackets(basePriceBrackets.slice());
    } else {
      setEditableBrackets([
        { maxValue: '1', basePrice: '1', costPerUnit: '', range: '1' },
        { maxValue: '', basePrice: '1', costPerUnit: '', range: '2+' },
      ]);
    }
  }, [basePriceBrackets]);

  const updateBracket = (index: number, field: keyof IRangedVariableBasePrice, value: string) => {
    setEditableBrackets((prevBrackets) => {
      const updatedBrackets = [...prevBrackets];
      updatedBrackets[index] = { ...updatedBrackets[index], [field]: value };
      return updatedBrackets;
    });
  };

  const editTable: IColumnConfig<IRangedVariableBasePrice>[]= useMemo(() => (
    [
      {
        headerLabel: 'Range Upper Limit',
        fieldName: 'maxValue',
        render: (data: IRangedVariableBasePrice, rowIndex) => (
          <TextField
            type="number"
            sx={{ bgcolor: 'text.disabled',  input: { color: 'white' }}}
            size="small"
            value={data.maxValue ?? ''}
            disabled={data.range.includes('+')}
            onChange={(e) => updateBracket(rowIndex, 'maxValue', e.target.value)}
          />
        ),
      },
      {
        headerLabel: 'Base Price',
        fieldName: 'basePrice',
        render: (data: IRangedVariableBasePrice, rowIndex) => (
          <TextField
            type="number"
            sx={{ bgcolor: 'text.disabled',  input: { color: 'white' }}}
            size="small"
            value={data.basePrice}
            onChange={(e) => updateBracket(rowIndex, 'basePrice', e.target.value)}
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
          />
        ),
      },
      {
        headerLabel: 'Cost Per Unit',
        fieldName: 'costPerUnit',
        render: (data: IRangedVariableBasePrice, rowIndex) => (
          <TextField
            type="number"
            sx={{ bgcolor: 'text.disabled',  input: { color: 'white' }}}
            size="small"
            value={data.costPerUnit ?? ''}
            onChange={(e) => updateBracket(rowIndex, 'costPerUnit', e.target.value)}
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
          />
        ),
      },
      {
        headerLabel: '',
        fieldName: 'range',
        headerRender: () => (
          <IconButton
            onClick={() => addBracket()}
          >
            <AddIcon
              sx={{
                backgroundColor: 'white',
                borderRadius: '5px',
                color: 'black',
                cursor: 'pointer',
                fontSize: '2rem',
                padding: 1,
                '&:hover': { opacity: 0.8 },
              }}
            />
          </IconButton>
        ),
        format: () => '',
      },
    ]
  ), []);

  const addBracket = () => {
    setEditableBrackets((prevBrackets) => {
      const numberOfBrackets = prevBrackets.length;
      const secondLastItem = prevBrackets[numberOfBrackets - 2];
  
      const newBracket: IRangedVariableBasePrice = {
        maxValue: `${intVal(secondLastItem.maxValue) + 1}`,
        basePrice: '1',
        costPerUnit: null,
        range: '1',
      };
  
      const updatedBrackets = [...prevBrackets];
      updatedBrackets.splice(numberOfBrackets - 1, 0, newBracket);
      return updatedBrackets;
    });
  };

  const formatAndUpdateBrackets = () => {
    onConfirm(appendRangeToSortedPriceBracketsForSubscription(editableBrackets));
    setOpen(false);
  };

  return <Dialog
    open={open}
    onClose={() => { onClose?.(); setOpen(false); }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    
    <DialogTitle id="alert-dialog-title">
      Add a new range for variable base price override
    </DialogTitle>
    
    <DialogContent>
      <HHSimpleTable<IRangedVariableBasePrice>
        rows={editableBrackets}
        columns={editTable}
        rowHover={false}
      />
    </DialogContent>
        
    <DialogActions>
      <Button color='error' onClick={() => { onCancel?.(); setOpen(false); }}>
        Cancel
      </Button>
    
      <Button onClick={() => formatAndUpdateBrackets()} autoFocus>
        Add
      </Button>
    </DialogActions>
  </Dialog>;
};
    