// src/ConfigEditor.tsx
import { Box, Button, IconButton, TextareaAutosize, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';

export type TCustomConfig = Record<string, unknown>;

type TConfigEditorProps = {
  customConfig: Record<string, unknown>;
  onUpdate: (newData: Record<string, unknown>) => void;
  boxColor?: string;
  title?: string
  defaultToEdit?: boolean

}

/**
 * Component to display an edit json objects
 * @param customConfig - json object to display/edit
 * @param onUpdate - callback to be called when changes are made
 * @param boxColor - color of the text area. Defaults to white
 * @param title - title for the text area section
 * @param defaultToEdit - whether it should default to edit mode
 * @returns Component to display and edit json objects
 */
export const HHConfigEditor = (
  { 
    customConfig, 
    onUpdate, 
    boxColor = 'white',
    title,
    defaultToEdit = false,
  }: TConfigEditorProps,
) => {
  const [text, setText] = useState<string>(JSON.stringify(customConfig, null, 2));
  const [error, setError] = useState<string>('');
  const [ canEditCustomConfig, setCanEditCustomConfig ] = useState<boolean>(!defaultToEdit); 

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    setText(newText);

    try {
      const newData = JSON.parse(text);
      onUpdate(newData);
      setError('');
    } catch {
      setError('Invalid JSON format');
    }
  };

  return (
    <>
      <Typography variant="h5">
        { title ?? 'Custom Configuration'}
        <IconButton
          color="inherit" 
          onClick={() => setCanEditCustomConfig(!canEditCustomConfig) }
        >
          {
            !canEditCustomConfig
              ? <EditOffIcon
                sx={{
                  backgroundColor: '#1976d2',
                  borderRadius: '5px',
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '2rem',
                  padding: 1,
                  '&:hover': { opacity: 0.8 },
                }}
              />
              : <EditIcon
                sx={{
                  backgroundColor: '#1976d2',
                  borderRadius: '5px',
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '2rem',
                  padding: 1,
                  '&:hover': { opacity: 0.8 },
                }}
              />
          }
        </IconButton>
      </Typography>
      <Box
        sx={{
          border: 1,
          borderRadius: 2,
          borderColor: 'grey.500',
          padding: 2,
          maxHeight: 400,
          overflowY: 'auto',
          resize: 'both',
          backgroundColor: boxColor,
          maxWidth: '100%',
          width: '100%',
        }}
      >
        
        <TextareaAutosize
          value={text}
          onChange={handleChange}
          readOnly={canEditCustomConfig}
          maxRows={6}
          style={{
            width: '100%',
            resize: 'none',
            overflowY: 'auto',
            borderColor: error ? 'red' : 'grey',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderRadius: '4px',
            boxSizing: 'border-box',
          }}
        />
        {error && (
          <Typography 
            variant="body2" 
            color="error"
            sx={{ marginTop: 1, marginLeft: 1 }}
          >
            {error}
          </Typography>
        )}
      </Box>
    </>
  );
};
