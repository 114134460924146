import { z } from "zod";
import {
  zICommissionSchemeEditable,
  zICommissionSchemesEditable,
  zISubscriptionHasBuildingsEditable,
  zISubscriptionsEditable, 
} from "../subscriptionSchemas";

export type SubscriptionEditable = z.infer<typeof zISubscriptionsEditable>;

export type ICommissionSchemeEditable = z.infer<typeof zICommissionSchemeEditable>;

export type ICommissionSchemesEditable = z.infer<typeof zICommissionSchemesEditable>;

export type ISubscriptionHasBuildingsEditable = z.infer<typeof zISubscriptionHasBuildingsEditable>

export type ISubscriptionVariableBasePriceEditable = Omit<
SubscriptionEditable, "baseSubscription" | "variableBasePriceOverrides"
> & {
  variableBasePriceOverrides: NonNullable<SubscriptionEditable["variableBasePriceOverrides"]>;
  baseSubscription: Omit<
  Exclude<SubscriptionEditable["baseSubscription"], undefined | null>,
  "variableBasePrices"
  > & {
    variableBasePrices: NonNullable<SubscriptionEditable["baseSubscription"]>["variableBasePrices"];
  };
}

export const isISubscriptionVariableBasePriceEditable = (
  object: unknown,
): object is ISubscriptionVariableBasePriceEditable => (
  !!(object as ISubscriptionVariableBasePriceEditable).baseSubscription 
  && !!(object as ISubscriptionVariableBasePriceEditable).baseSubscription.variableBasePrices
  && !!(object as ISubscriptionVariableBasePriceEditable).variableBasePriceOverrides 
);

export const assertIsISubscriptionVariableBasePriceEditable = (object: unknown) => {
  if (isISubscriptionVariableBasePriceEditable(object)) {
    return object;
  }
  throw new Error(`object provided is not of type ISubscriptionVariableBasePriceEditable: ${JSON.stringify(object)}`);
};